import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import Link from "components/link"
import BackgroundImage from "components/backgroundImage"
import { getUrlPath } from "utils/getUrlPath"

const LinkBoxes = (props) => {
  const { linkBoxes } = useLinkBoxes()

  const items = linkBoxes.find((o) => o.languageCode === process.env.GATSBY_ACTIVE_LANGUAGE)

  return (
    <>
      {items && (
        <Container marginTop={props?.marginTop}>
          {items.boxes.map(({ link, image }, index) => (
            <Box key={index} to={getUrlPath(link.url)}>
              <LinkTitle variant="h3" component="div" color="inherit">
                {link.title}
              </LinkTitle>
              {image && <BackgroundImage position="absolute" image={image} maxWidth={1280} />}
            </Box>
          ))}
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: ${(props) => (props?.marginTop || props?.marginTop === 0 ? props?.marginTop : "5vh")};

  @media (min-width: 580px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Box = styled(Link)`
  min-height: 190px;
  width: 100%;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover {
    &:after {
      opacity: 1;
    }
  }

  &:after {
    transition: 0.3s opacity ease-in-out;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0.5;
  }

  @media (min-width: 580px) {
    flex: 1;
    min-width: 300px;
    min-height: 300px;
  }
`
const LinkTitle = styled(Typography)`
  position: relative;
  z-index: 1;
  padding: 0 30px;
`

const useLinkBoxes = () => {
  const {
    wp: {
      themeSettings: { siteOptions }
    }
  } = useStaticQuery(
    graphql`
      {
        wp {
          themeSettings {
            siteOptions {
              linkBoxes {
                languageCode
                boxes {
                  link {
                    url
                    title
                  }
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 960, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return siteOptions
}

export default LinkBoxes
